// Simplified code from https://www.8thwall.com/8thwall/camera-qrcode

import jsQR from 'jsqr'
import { mapSearchParams, isNewSearchParam, updateSearchParams } from '../../utils/search-params';
import { setCameraFeedResumeStatus, isCameraFeedResuming, isQRCodeInactivated } from '../../utils/camera-feed';
import { sendErrorReport } from '../../utils/sentry-error';

// Define a custom pipeline module. This module scans the camera feed for qr codes, and makes the
// result available to other modules in onUpdate. It requires that the CameraPixelArray module is installed.
const qrprocessPipelineModule = () => {
  let date = Date.now();

  return {
    name: 'qrprocess',
    onProcessCpu: ({processGpuResult}) => {
      // Check whether there is any data ready to process.
      if (!processGpuResult.camerapixelarray || !processGpuResult.camerapixelarray.pixels || isQRCodeInactivated()) {
        return
      }
  
      // Only check every 500 ms to decrease the workload for the phone
      if (Date.now() - date < 500 || isCameraFeedResuming()) {
        // The canvas buffers the last camera frames, and these needs to be cleared, otherwise the same image target or QR code will be triggered again
        if (isCameraFeedResuming()) {
          date = Date.now();
          setCameraFeedResumeStatus(false);
        }

        return
      }
  
      date = Date.now();

      try {
        const {rows, cols} = processGpuResult.camerapixelarray
  
        // Returns  binaryData: number[], data: string; chunks: Chunks; version: number; location: Points{}
        return jsQR(processGpuResult.camerapixelarray.pixels, cols, rows, {inversionAttempts: 'invertFirst'});
      } catch (error) {
        sendErrorReport(error);
      }
    },
  }
}

// Define a custom pipeline module to notify other modules about finding a QR code
const qrdisplayPipelineModule = () => {
  const host = location.host;
  let newUrl, correctHost;
  
  return {
    name: 'qrdisplay',
    onUpdate: ({processCpuResult}) => {
      if (processCpuResult.qrprocess?.data) {
        try {
          newUrl = new URL(processCpuResult.qrprocess.data);
          correctHost = host == newUrl.host;  // don't want to trigger QR Codes pointing to another domain
          
          if (isNewSearchParam(newUrl.href) && correctHost) {
            updateSearchParams(mapSearchParams(newUrl.searchParams));

            window.dispatchEvent(new CustomEvent(
              'ec-searchparams-change',
              {detail: mapSearchParams(newUrl.searchParams)}
            ));
          }
        } catch (error) {
          sendErrorReport(error);
        }
      }
    },
  }
}

export {qrprocessPipelineModule, qrdisplayPipelineModule}
