import EcCustomElement from '../../ec-custom-element';
import './../../../../scss/elements/ec-audio-player.scss';

export default class EcAudioGalleryItem extends EcCustomElement {
  html() {
    return `
      <div class="ec-audio-gallery-item__title"></div>
      <div class="ec-audio-gallery-item__duration">...</div>
    `
  }

  static observedAttributes = ['title', 'duration'];

  attributeChangedCallback(name, oldValue, newValue) {
    this.querySelector('.ec-audio-gallery-item__' + name).innerText = newValue;
  }
}

customElements.define('ec-audio-gallery-item', EcAudioGalleryItem);
