import { setScale } from "../image-targeting/image-target-helper";
import { analyticsSendTrigger } from "../../utils/matomo-analytics";
import { getSearchParams } from "../../utils/search-params";
import { sendErrorReport } from "../../utils/sentry-error";
import { getCurrentLanguage } from "../../utils/language-helper";

const playVideoComponent = {
  schema: {
    videourl: {type: 'string'},
    thumb: {type: 'string'},
    autoplay: {type: 'boolean'},
    loop: {type: 'boolean'},
    scaleonmax: {type: 'boolean'}
  },
  video: null,
  thumb: null,
  parentImageTarget: null,  
  videoName: null,
  sentTrigger: false,

  __playEvent: null,
  __endEvent: null,

  /**
   * Initialize variables, depending if the video is autoplaying or not
   */
  init() {
    if (this.data.videourl == '') { return }

    this.__playEvent = this.playVideo.bind(this);
    this.__endEvent = this.videoEnded.bind(this);
    this.__loadedEvent = this.showVideoAfterLoaded.bind(this);
    this.__updateDuration = this.updateDuration.bind(this);
    this.__updateCurrentTime = this.updateCurrentTime.bind(this);

    this.el.addEventListener('click', this.__playEvent);

    this.video = this.createVideoElement(this.data.videourl);
    this.thumb = this.data.thumb && document.querySelector(this.data.thumb);
    this.parentImageTarget = this.el.parentEl.components;
    this.videoName = this.el.parentEl.getAttribute('ec-named-image-target').name;

    if (this.data.autoplay) {
      this.video.load();
      this.playVideo();
    } else {
      this.showThumbnail();
    }
},

  // Also removed in the component 'ec-named-image-target'
  remove() {
    this.sendProgressToAnalytics();
    this.video.pause();
    this.video.src = '';
    this.sentTrigger = false;
    this.parentImageTarget.isActive = false;
    this.parentImageTarget['ec-named-image-target'].data.isBuffering = false;
    this.el.removeEventListener('click', this.__playEvent);
    this.video.removeEventListener('ended', this.__endEvent);
    this.video.removeEventListener('play', this.__loadedEvent);
    this.video.removeEventListener('loadedmetadata', this.__updateDuration);
    this.video.removeEventListener('timeupdate', this.__updateCurrentTime);
    this.toggleCloseButton('close');
    this.__playEvent = null;
    this.__endEvent = null;
    this.__loadedEvent = null;
    this.video = null;
    this.thumb = null;
  },

  /**
   * Creates a video element based on the 'autoplay' property in the manifest
   * @param {string} url Can be language dependent if 'lang:1' is set in the manifest
   * 
   * @returns video element
   */
  createVideoElement(url) {
    const videoEl = document.createElement('video');
    videoEl.crossOrigin = 'anonymous';
    videoEl.playsInline = true;
    videoEl.webkitPlaysInline = true;

    if (this.data.autoplay) {
      videoEl.setAttribute('preload', 'auto');
      videoEl.setAttribute('muted', '');
      if (this.data.loop) { videoEl.setAttribute('loop', ''); }
    } else {
      videoEl.addEventListener('ended', this.__endEvent);
    }

    videoEl.addEventListener('play', this.__loadedEvent);
    videoEl.addEventListener('loadedmetadata', this.__updateDuration);
    videoEl.addEventListener('timeupdate', this.__updateCurrentTime);

    videoEl.src = url;

    return videoEl
  },

  /**
   * Sends the event that it has reached 10%, 20%, ... 100%.
   */
  sendProgressToAnalytics() {
    const wholeTenPercentage = Math.round(this.video.currentTime / this.video.duration * 10)*10;

    if (wholeTenPercentage && !this.data.autoplay) {
      analyticsSendTrigger('videoProgress', "video: " + this.videoName, wholeTenPercentage);
    }
  },

  /**
   * Loops through all image target elements to see if they are set to active
   */
  stopPreviousPlayback() {
    for (const element of this.el.sceneEl.components.pool__imagetarget.usedEls) {
      if (element.components.isActive
          && this.videoName !== element.getAttribute('ec-named-image-target').name) {
        // console.log('stopPreviousPlayback', this.videoName, element.getAttribute('ec-named-image-target').name);
        this.el.emit('ec-camera-feed-delegator', {action: 'close', name: element.getAttribute('ec-named-image-target').name});
      }
    }
  },

  /**
   * It (probably) takes time to load in the texture, creating a black square
   * This method listens to the 'play' event and delays showing the video for a while
   * If the video can't be shown within 30*200 ms, the component will be removed
   */
  showVideoAfterLoaded() {
    this._showVideoAfterLoadedHelper(0);
  },

  _showVideoAfterLoadedHelper(retry) {
    setTimeout(() => {
      if (this.video?.videoWidth) {
        // If 8thwall cropped the wrong dimension, scale_on_max_dimension is set in the manifest file
        let dimensionToScale = this.data.scaleonmax ? this.el.components.maxDimension : this.el.components.minDimension
        setScale(this.el, this.video.videoWidth, this.video.videoHeight, dimensionToScale);
  
        this.el.object3D.visible = true

         // Show progress bar for video
        this.el.emit('ec-timestamp-visualizer', {visibility: true})

        if (!this.data.autoplay) {
          this.toggleCloseButton('show');
        }  

        // For the game
        this.parentImageTarget['ec-named-image-target'].data.hasPlayed = true;
      } else if (this.video && retry < 30) {
        // Texture hasn't loaded yet. Retrying...
        this._showVideoAfterLoadedHelper(++retry);
      } else {
        // If the user hasn't removed the video before the video has loaded
        if (this.video) {
          sendErrorReport(new Error(`Video error loading: Couldn't load ${this.videoName} for lang ${getCurrentLanguage()}`));
        }

        this.removeVideo();
      }
    }, 200)
  },

  showThumbnail() {
    this.el.setAttribute('class', 'cantap');  
    this.el.object3D.visible = true;

    this.el.setAttribute('material', {
      src: this.thumb || this.video,
      shader: 'flat',
      color: "#FFF"   // will display a warning if this isn't set
    });

    this.el.emit('ec-onboarding-close');

    if (this.thumb) {
      setScale(this.el, this.thumb.width, this.thumb.height, this.el.components.minDimension);
    }

    // Used by 'dynamic-targeting' to see if it should continue playing
    // the video when loosing the image target
    this.parentImageTarget.isActive = false;
  },

  playVideo() {
    if (!this.parentImageTarget.isActive) {
      if (!this.sentTrigger) {
        analyticsSendTrigger('videoPlay', "room: " + getSearchParams().roomId, this.videoName);
        this.sentTrigger = true;
      }

      this.el.object3D.visible = false;

      this.el.emit('ec-onboarding-close');

      // Setting 'src' will replace the thumbnail
      this.el.setAttribute('material', {
        src: this.video,
        shader: 'chromakey',
        color: '0 1 0',
      });

      // Tells 'dynamic-targeting' to not remove this
      //    if playing a video
      //    while image target is lost
      this.parentImageTarget.isActive = !this.data.autoplay;

      this.stopPreviousPlayback();

      this.video.play()
        .catch(error => {
          // if iOS Safari is on low power mode
          if (error.name === 'NotAllowedError' && this.data.autoplay) {
            this.showThumbnail();
          } else {
            this.toggleCloseButton('close');
            this.removeVideo();

            sendErrorReport(error);
          }
        });
    } else if (!this.data.autoplay) {
      this.video.pause();

      this.parentImageTarget.isActive = false;
    }
  },

  toggleCloseButton(action) {
    this.el.emit('ec-close-button', {
      name: 'ec-camera-feed-delegator',
      action: action,
      data: {
        name: this.videoName
      }
    });  
  },

  /**
   * Notifies 'ec-timestamp-visualizer' about the duration
   */
  updateDuration(event) {
    this.el.emit('ec-timestamp-visualizer', {duration: this.video.duration});
  },

  /**
   * Notifies 'ec-timestamp-visualizer' about the duration
   */
  updateCurrentTime(event) {
      this.el.emit('ec-timestamp-visualizer', {currentTime: this.video.currentTime});
    },

  /**
   * Removes the video if it's not in focus, otherwise: show the thumbnail
   */
  videoEnded() {
    analyticsSendTrigger('videoEnded', "room: " + getSearchParams().roomId, this.videoName);
    this.sentTrigger = false;

    this.parentImageTarget.isActive = false;
    this.toggleCloseButton('close');
    if (this.el.object3D.visible) {
      this.showThumbnail();
      this.sendProgressToAnalytics();
      this.video.currentTime = 0;
      
    } else {
      this.removeVideo();
    }

    // for the game
    this.el.emit(this.videoName);
  },

  /**
   * Need to tell camera-feed-delegator that this one needs to be removed, which deactivate this parent: ec-named-image-target
   */
  removeVideo() {
    this.parentImageTarget.isActive = false;
    this.el.emit('ec-remove-image-target', {name: this.videoName});
  }
}

export {playVideoComponent}
