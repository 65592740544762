const AVOID_BLACK_SCREEN_IN_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) !== true;

/**
 * Extracts search params from url
 * @param {string} url 
 * @returns {{string:string}object} see mapSearchParams
 */
const getSearchParams = (url) => {
  url = url || window.searchParams || location.href;

  return mapSearchParams(new URL(url).searchParams)
}

/**
 * Mapping search params
 * @param {object} searchParams as from a URL object
 * @returns {object}
 */
const mapSearchParams = (searchParams) => {
  const DEFAULT_TO_ENTRANCE = 1;

  return {
    'roomId': searchParams.get('rid') || DEFAULT_TO_ENTRANCE,
    'pointId': searchParams.get('pid')
  }
}

/**
 * Used in QR Code reader to see if it should trigger
 * @param {string} url 
 * @returns {boolean} is this a new search param?
 */
const isNewSearchParam = (url) => {
  const oldSearchParams = getSearchParams()
  const newSearchParams = getSearchParams(url);

  return oldSearchParams.roomId !== newSearchParams.roomId || oldSearchParams.pointId !== newSearchParams.pointId
}

/**
 * Unless Safari, update history. iOS 16+ makes the canvas flash in black if updating history.
 * 
 * @param {string} roomId 
 * @param {string} pointId 
 */
const updateSearchParams = ({roomId, pointId}) => {
  const _pointId = (pointId) ? '&pid=' + pointId : '';

  if (AVOID_BLACK_SCREEN_IN_SAFARI) {
    history.replaceState('', '', location.origin + location.pathname + '?rid=' + roomId + _pointId);
  }

  window.searchParams = location.origin + location.pathname + '?rid=' + roomId + _pointId;
}

/**
 * Used for retriggering an image target after its closed
 * @param {string} pointId 
 */
const removePointFromSearchParams = (pointId, alwaysRemove) => {
  if (alwaysRemove || getSearchParams().pointId === pointId) {
    updateSearchParams({roomId: getSearchParams().roomId});
    window.dispatchEvent(new Event('ec-onboarding'));
    window.dispatchEvent(new CustomEvent('ec-camera-feed-delegator', {detail: {action: 'show-room-information'}}));
  }
}

export { getSearchParams, mapSearchParams, isNewSearchParam, updateSearchParams, removePointFromSearchParams }
