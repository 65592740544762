const DEFAULT_LANGUAGE = 'en';

// In addition to DEFAULT_LANGUAGE (English)
const supportedLanguages = {
  'sv-SE': 'sv',
  'sv-FI': 'sv',
  'sv': 'sv',
};

/**
 * Retrieves current language from memory, localstorage or browser.
 * Sets DEFAULT_LANGUAGE if supported language isn't available.
 *
 * @return {string} ex. en, sv
 */
const getCurrentLanguage = () => {
  window.currentLanguage = window.currentLanguage || localStorage.getItem('currentLanguage');

  if (!supportedLanguages[window.currentLanguage] && window.currentLanguage !== DEFAULT_LANGUAGE) {
    window.currentLanguage = supportedLanguages[navigator.language] || DEFAULT_LANGUAGE;
    setCurrentLanguage(window.currentLanguage);
  }

  return window.currentLanguage;
}

/**
 * Sets a new language in localstorage
 *
 * @param {string} newLang - Language code
 * @return {string} ex. en, sv
 */
const setCurrentLanguage = (newLang) => {
  localStorage.setItem('currentLanguage', newLang);
  window.currentLanguage = newLang;

  window.dispatchEvent(new CustomEvent(
    'ec-language-change',
    {detail: {lang: newLang}}
  ));

  return newLang;
}

/**
 * Translates a string
 * 
 * @param {string} key - a sentence or a unique string
 * @return {string} the translated text, or the key if the text doesn't exist
 */
const localize = (key) => {
  return getDictionary(key);
}

/**
 * Finds elements based on id, and localize it based on the same name
 * Need to call this through localizeElements.call(this) in order get the correct scope
 * 
 * @param {HTMLElement} scope - custom element
 * @param {array} elArr - containing strings
 * @return {void}
 */
const localizeElements = (scope, elArr) => {
  let el;

  elArr.forEach((elStr) => {
    el = scope.querySelector('#' + elStr);

    if (el) {
      el.textContent = localize(elStr.replaceAll('-', '_'));
    } else {
      console.warn("Can't find element", elStr, 'in', scope.tagName);
    }
  });
}

/**
 * Retrieves the dictionary and returns the correct translation based on the language
 * 
 * @param {string} key - a sentence or a unique string
 * @return {string} a text string based on language, default language, or "key" if the text doesn't exist
 */
const getDictionary = (key) => {
  let translation = dictionary[key];

  if (translation) {
    return dictionary[key][getCurrentLanguage()] || dictionary[key][DEFAULT_LANGUAGE] || key;
  }

  return key;
}

// TODO: download a list from Scaleway instead, but because there can be multiple calls at the same time, all calls then needs to chain
const dictionary = {
  'welcome_to': {
    'en': 'Welcome To',
    'sv': 'Välkommen till',
  },
  'tagline': {
    'en': 'Hold on, we\'re sprinkling magic in our rooms.',
    'sv': 'Vänta lite, vi sprider magi i våra rum.',
  },
  'discover_cta_title': {
    'en': 'Discover the Room',
    'sv': 'Upptäck rummet',
  },
  'discover_cta_body': {
    'en': 'Sweep the camera over objects or QR codes.',
    'sv': 'Rikta kameran mot föremål eller QR-koder.',
  },
  'reload_title': {
    'en': 'New Update Available',
    'sv': 'Ny uppdatering finns',
  },
  'reload_body': {
    'en': 'Please tap here to update.',
    'sv': 'Klicka här för att uppdatera.',
  },
  'safari_gyro_titel': {
    'en': 'Shall we, please?',
    'sv': 'Får vi lov?',
  },
  'safari_gyro_body': {
    'en': 'Dunkers need the device\'s motion sensors in order for AR to work.',
    'sv': 'Dunkers behöver telefonens rörelsesensor för att AR ska fungera.',
  },
  'button_continue': {
    'en': 'Continue',
    'sv': 'Fortsätt',
  },
  'button_approve': {
    'en': 'Approve',
    'sv': 'Godkänn',
  },
  'button_refresh': {
    'en': 'Refresh',
    'sv': 'Ladda om',
  },
  'button_cancel': {
    'en': 'Cancel',
    'sv': 'Avbryt',
  },
  'camera_denied_safari_reload': {
    'en': 'Reload the page and allow camera access.',
    'sv': 'Ladda om sidan och tillåt tillgång till kameran.',
  },
  'camera_denied_safari_bottom': {
    'en': 'Ensure camera access is allowed in app settings.',
    'sv': 'Kontrollera även att kameraåtkomst är tillåten i telefonens inställningar.',
  },
  'motion_denied_header': {
    'en': 'Loading Cancelled',
    'sv': 'Laddning avbruten',
  },
  'motion_denied_body': {
    'en': 'You need to accept motion permissions to use the app.',
    'sv': 'Du måste tillåta användning av rörelsesensor för att använda appen.',
  },
  'motion_hard_deny_body': {
    'en': 'Please close Safari to reenable your motion sensor.',
    'sv': 'Stäng Safari för att få frågan på nytt.',
  },
  'camera_denied_android_header': {
    'sv': 'Aktivera kameran för att använda appen',
  },
  'camera_denied_android_reload': {
    'sv': 'Ladda slutligen om sidan.',
  },
  'camera_denied_android_general_step1': {
    'sv': 'Klicka på de tre prickarna i övre högra hörnet',
  },
  'camera_denied_android_general_step2': {
    'sv': 'Välj Inställningar',
  },
  'camera_denied_android_step3': {
    'sv': 'Klicka på Webbplatsinställningar',
  },
  'camera_denied_android_step4': {
    'sv': 'Kamera',
  },
  'camera_denied_android_step5': {
    'sv': 'Blockerat → https://' + location.host,
  },
  'camera_denied_android_step6': {
    'sv': 'Ta bort',
  },
  'camera_denied_samsung_step3': {
    'sv': 'Platser och hämtningar',
  },
  'camera_denied_samsung_step4': {
    'sv': 'Webbplatsbehörigheter',
  },
  'camera_denied_samsung_step5': {
    'sv': 'Kamera',
  },
  'camera_denied_samsung_step6': {
    'sv': 'Aktivera https://' + location.host,
  },
  'game1_wrong_target': {
    'en': 'You found something, but at the wrong place. Try looking somewhere else.',
    'sv': 'Du har hittat något, men på fel ställe. Sök någon annanstans.',
  },
  'game1_onboarding': {
    'en': 'Torsten has a problem. He lost the code to his safe. Help him to find the code.',
    'sv': 'Torsten har ett problem. Han har förlorat koden till kassaskåpet. Hjälp honom att finna koden.',
  },
  'clue': {
    'en': 'Clue',
    'sv': 'Ledtråd'
  },
  'game1_clue0': {
    'en': 'Find the portrait of Torsten Mårtensson.',
    'sv': 'Finn Torsten Mårtenssons porträtt.',
  },
  'game1_clue1': {
    'en': 'Track down Gisela! She has a hat and a white collar.',
    'sv': 'Hitta Gisela! Hon har hatt och vit krage.',
  },
  'game1_clue1_gisella_title': {
    'en': 'Clue: 2/5',
    'sv': 'Ledtråd: 2/5',
  },
  'game1_clue1_gisella_body': {
    'en': 'Tap the symbol in the bottom left.',
    'sv': 'Klicka på symbolen i nedre vänstra hörnet.',
  },
  'game1_clue2': {
    'en': 'Search in the open book by the trumpets.',
    'sv': 'Sök i den uppslagna boken vid trumpeterna.',
  },
  'game1_clue3': {
    'en': 'Look in the maroon drawer.',
    'sv': 'Titta i den rödbruna skrivbordslådan.',
  },
  'game1_clue4': {
    'en': 'Locate the portrait of Oscar Trapp.',
    'sv': 'Leta reda på Oscar Trapps porträtt.',
  },
  'game1_clue5': {
    'en': 'The day Helsingborg was founded is equal to the number of axes in the front row on the third shelf',
    'sv': 'Dagen då Helsingborg grundades är densamma som antalet yxor på den tredje hyllans främre rad',
  },
  'game1_nar_blev_helsingborg_en_stad': {
    'en': 'Clues',
    'sv': 'Ledtrådar',
  },
  'game1_strongbox_title': {
    'en': 'Have you figured out the code?',
    'sv': 'Har du listat ut koden?',
  },
  'game1_strongbox_body': {
    'en': 'The code to Torsten\'s safe is the birthdate of Helsingborg. Do you remember it?',
    'sv': 'Koden till Torstens kassaskåp är Helsingborgs födelsedatum. Kommer du ihåg det?',
  },
  'game1_strongbox_error_text': {
    'en': 'Wrong code. You can open the clues to spot what you got wrong.',
    'sv': 'Fel kod. Du kan öppna ledtrådarna och se vad du har missat.',
  },
  'game1_winner_page_title': {
    'en': 'Good work!',
    'sv': 'Bra jobbat!',
  },
  'game1_winner_page_body_first': {
    'en': 'You helped Torsten to open his safe, and meanwhile learned when Helsingborg became a city.',
    'sv': 'Ni har hjälpt Torstens att öppna sitt kassaskåp och samtidigt lärt er när Helsingborg blev en stad.',
  },
  'game1_winner_page_body_second': {
    'en': 'Psst ... if you wonder what Torsten had in his safe, it was his reading glasses—so he wouldn\'t lose them!',
    'sv': 'Psst... undrar du vad Torsten hade i kassaskåpet? Det var hans läsglasögon - så att han skulle slippa bli av med dem!',
  },
  'game1_only_took': {
    'en': 'It only took',
    'sv': 'Det tog bara',
  },
  'min': {
    'en': 'min',
    'sv': 'min',
  },
  'sec': {
    'en': 'sec',
    'sv': 'sek',
  },
  'bildspel_industri_och_slojdutstallning': {
    'en': 'Industry and Woodwork Exhibition',
    'sv': 'Industri- och slöjdutställning',
  },
  'bildspel_rostrattsinsamling_1914': {
    'en': 'Suffrage Collection',
    'sv': 'Rösträttsinsamling, 1914',
  },
  'bildspel_centralstationen': {
    'en': 'Central Station',
    'sv': 'Centralstationen',
  },
  'bildspel_st_maria': {
    'en': 'St Maria',
    'sv': 'St Maria',
  },
  'bildspel_frohandel': {
    'en': 'Seed Trade',
    'sv': 'Fröhandel',
  },
  'bildspel_hefoma_och_hogvaktens_konditori': {
    'en': 'Heforma and Högvakten\'s bakery',
    'sv': 'Heforma och Högvaktens konditori',
  },
  'bildspel_metoo_och_mandagsrorelsen': {
    'en': 'MeToo and Måndagsrörelsen',
    'sv': 'MeToo och Måndagsrörelsen',
  },
  'bildspel_brottsmal_hilda_nilsson': {
    'en': 'Case: Hilda Nilsson',
    'sv': 'Brottsmål: Hilda Nilsson',
  },
  'bildspel_hbg_varf_abs_100_fartyg': {
    'en': 'Ships',
    'sv': 'Fartyg',
  },
  'bildspel_elfenbensminiatyrer': {
    'en': 'Miniatyres in Ivory',
    'sv': 'Miniatyrer i elfenben',
  },
  'bildspel_hanna_christer_nilsson_brev': {
    'en': 'Letters from Hanna Christer Nilsson',
    'sv': 'Hanna Christer Nilssons brev',
  },
  'bildspel_fanor': {
    'en': 'Flags',
    'sv': 'Fanor',
  },
  'year': {
    'en': 'Year',
    'sv': 'År',
  },
  'month': {
    'en': 'Month',
    'sv': 'Månad',
  },
  'day': {
    'en': 'Day',
    'sv': 'Dag',
  },
  'model_loading_title': {
    'en': 'Please hold',
    'sv': 'Vänligen vänta',
  },
  'model_loading_body': {
    'en': 'Loading the 3D model...',
    'sv': 'Laddar 3D-modellen...',
  },
  'ios_audio_loaded_title': {
    'en': 'Audio loaded',
    'sv': 'Ljudet har laddats',
  },
  'ios_audio_loaded_body': {
    'en': 'The player is ready go to',
    'sv': 'Spelaren är redo att sätta igång',
  },

  'key_change_me': {
    'en': 'translated_text_change_me',
    'sv': 'translated_text_change_me',
  },
}

export { getCurrentLanguage, setCurrentLanguage, localize, localizeElements };
