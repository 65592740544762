// TODO: Make this into a proper, and general, local storage handler
export default class ProcessStorage {
  PROGRESS_KEY = '_game_progress';

  constructor(gameName) {
    this.gameName = gameName;
  }

  read() {
    return Number(localStorage.getItem(this.gameName + this.PROGRESS_KEY)) || 0;
  }
  
  store(progress) {
    localStorage.setItem(this.gameName + this.PROGRESS_KEY, progress);
  }
  
  remove() {
    localStorage.removeItem(this.gameName + this.PROGRESS_KEY);
  }
}
