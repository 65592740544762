import EcCustomElement from '../ec-custom-element';
import './../../../scss/elements/ec-onboarding.scss';
import { getSearchParams } from '../../utils/search-params';

export default class EcOnboarding extends EcCustomElement {
  html() {
    return `
      <svg class="onboarding__icon">
        <use xlink:href="bundle.svg#icon-move"/>
      </svg>
    `
  }

  constructor() {
    super();

    this.showOnboardingOnStartUp();

    window.addEventListener('ec-onboarding', this.showOnboarding.bind(this));

    window.addEventListener('ec-onboarding-close', this.hideOnboarding.bind(this));
    window.addEventListener('ec-searchparams-change', this.hideOnboarding.bind(this));
    window.addEventListener('xrimagefound', this.hideOnboarding.bind(this));
    window.addEventListener('ec-play-audio', this.hideOnboarding.bind(this));
    window.addEventListener('ec-play-video', this.hideOnboarding.bind(this));
    window.addEventListener('ec-start-game', this.hideOnboarding.bind(this));
  }

  /**
   * Only show the onboarding if it has no elements loaded, and after XR has started
   */
  showOnboardingOnStartUp() {
    if (!getSearchParams().pointId) {
      const load = () => {
        window.removeEventListener('xrloaded', load);
        
        this.showOnboarding();
      }
      
      window.XR8 ? load() : window.addEventListener('xrloaded', load);
    }
  }

  showOnboarding() {
    this.classList.remove('hide-animation--hidden');
  }

  // TODO: don't hide onboarding if the image target is part of a game, and the user scans an image target pre-game
  // Need to save room layouts in memory for this to happen
  hideOnboarding() {
    this.classList.add('hide-animation--hidden');
  }
}

customElements.define('ec-onboarding', EcOnboarding);
