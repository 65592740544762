/**
 * Creates a close button in the bottom right corner.
 * Sends out an event when clicked upon:
 *
 * Example: ec-close-button show up, and send 'ec-camera-feed-delegator' when clicked upon
    this.el.emit('ec-close-button', {
      name: 'ec-camera-feed-delegator', // 'ec-camera-feed-delegator' got a listener with the same name
      action: 'show',                   // 'close' will remove the newest event
      layered: 1                        // used by a component that has several layers of depth to navigate into
      data: {}                          // sent as detail for ec-camera-feed-delegator
    });
 */
import EcCustomElement from '../ec-custom-element';
import './../../../scss/elements/ec-fab-button.scss';

export default class EcCloseButton extends EcCustomElement {
  storedEvents = [];
  lockLayered = 0;    // locks close button with layers (backbutton) until released

  html() {
    return `
      <button class="primary ec-fab-button__button">
        <svg class="ec-fab-button__icon_close hide-animation" viewBox="0 0 40 40" xmlns:xlink="http://www.w3.org/1999/xlink">
          <use xlink:href="bundle.svg#icon-cancel"/>
        </svg>
        <svg class="ec-fab-button__icon_back hide-animation hide-animation--hidden" viewBox="0 0 40 40" xmlns:xlink="http://www.w3.org/1999/xlink">
          <use xlink:href="bundle.svg#icon-arrow-back"/>
        </svg>
      </button>
    `
  }

  constructor() {
    super();

    window.addEventListener(this.is, this.handleEvent.bind(this));

    this.addEventListener('click', () => {
      // NOTE: each element is closing this button via the event above, not the button itself.
      this.sendCloseEvent();
      // this.removeNewestEvent();
    });
  }

  /**
   * Listens on 'ec-close-button' event
   * @param {object} detail data from event
   */
  handleEvent({detail}) {
    if (detail.action == 'show' && detail.data) {
      this.addClass(detail.name)
      this.addNewEvent(detail);
    } else if (detail.action == 'close') {
      this.removeNewestEvent();
    } else {
      console.warn(this.is, ': event is missing an "action" or "data" property.', detail);
    }
  }

  /**
   * It's possible to have multiple layers, where the close button toggles a back button instead of a close button
   * @param {object} detail data from event
   */
  addNewEvent(detail) {
    if (this.lockLayered) {
      this.storedEvents.unshift(detail);
      this.switchIcon(this.storedEvents.length);
    } else {
      this.lockLayered = this.lockLayered || detail.layer;

      this.removeClass(this.storedEvents[0]?.name);
      this.storedEvents[0] = detail;
      this.addClass(detail.name)
      
      this.toggleVisibility();
    }
  }

  removeNewestEvent() {
    if (this.storedEvents.length) {
      this.removeClass(this.storedEvents[0].name);
      this.switchIcon(this.storedEvents.length * -1);
      this.storedEvents.shift();
      this.toggleVisibility();
  
      this.lockLayered = this.storedEvents.length;  
    }
  }

  /**
   * Adds a class based on the type of event for specific styling (namely audio and video)
   */
  addClass(name) {
    if (name) {
      this.classList.add('ec-fab-button--' + name);
    }
  }

  removeClass(name) {
    if (name) {
      this.classList.remove('ec-fab-button--' + name);
    }
  }

  /**
   * Whatever element that activates ec-close-button can send in what data ec-close-button should send back
   * NOTE: each element is closing this button, not the button itself.
   */
  sendCloseEvent() {
    if (this.storedEvents[0]) {
      window.dispatchEvent(new CustomEvent(
        this.storedEvents[0].name,
        { detail: {...{action: 'close'}, ...this.storedEvents[0].data} }
      ));
    }
  }

  /**
   * Switches icon if more than one event is being tracked.
   * Should only switch if it goes between 1 and 2 stored events.
   * @param {int} numberOfStoredEvents Positive if adding an event, negative if subtracting
   */
  switchIcon(numberOfStoredEvents) {
    if (numberOfStoredEvents == 2) {
      this.querySelector('.ec-fab-button__icon_close').classList.toggle('hide-animation--hidden', true);
      this.querySelector('.ec-fab-button__icon_back').classList.toggle('hide-animation--hidden', false);
    } else if (numberOfStoredEvents == -2) {
      this.querySelector('.ec-fab-button__icon_close').classList.toggle('hide-animation--hidden', false);
      this.querySelector('.ec-fab-button__icon_back').classList.toggle('hide-animation--hidden', true);
    }
  }

  toggleVisibility() {
    this.classList.toggle('hide-animation--hidden', this.storedEvents.length == 0);
  }
}

customElements.define('ec-close-button', EcCloseButton);
