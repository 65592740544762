import EcCustomElement from '../../ec-custom-element';
import './ec-audio-gallery-item';
import './../../../../scss/elements/ec-audio-player.scss';

import { getCurrentLanguage } from '../../../utils/language-helper';
import { getTimeString } from '../../../utils/time-handling';

export default class EcAudioGallery extends EcCustomElement {
    tracks = [];
    selectedTrack = 0; // first item
    trackDurations = {};

    html() {
      return `
        <div id="gallery-container"></div>

        <template id="audio-gallery-item">
          <ec-audio-gallery-item class="ec-audio-gallery-item" title duration="..."></ec-audio-gallery-item>
        </template>
      `
    }

    connectedCallback() {
      window.addEventListener('ec-play-audio', this.handleEvent.bind(this));
      window.addEventListener('ec-language-change', this.handleLanguageChange.bind(this));
      window.addEventListener('ec-timestamp-visualizer', this.handleDurationChange.bind(this));

      this.querySelector('#gallery-container').addEventListener('click', this.audioItemClicked.bind(this));

      this.VERSION_CODE = this.getAttribute('version-code');

      this.lang = getCurrentLanguage();
    }

    handleLanguageChange({detail}) {
      this.lang = detail.lang;

      if (this.tracks.length) {
        this.startAudioGallery(this.tracks);
      }
    }

    handleEvent({detail}) {
      if (detail.action == 'start') {
        this.galleryId = detail.name;
        this.selectedTrack = 0;
        this.startAudioGallery(detail.tracks);
      }
    }

    /**
     * Creates child elements and downloads meta data (duration) for these elements
     * @param newTracks Tracks inside a gallery (even if it's only one)
     */
    startAudioGallery(newTracks) {
      this.tracks = newTracks;
      this.galleryContainer = this.querySelector("#gallery-container");

      this.createAudioItems(newTracks, this.galleryContainer);
      this.updateMetadata(this.galleryId, newTracks, this.galleryContainer.firstChild.children);
    }

    audioItemClicked(event) {
      let clickedGalleryItem = this.clickedOnGalleryItem(event.target, event.currentTarget.id);

      if (clickedGalleryItem) {
        this.switchAudioTrack(clickedGalleryItem.getAttribute('data-index'));
      }
    }

    /**
     * Toggles selected classes and notifies ec-play-audio about the new track
     * @param newTrackIndex the index of the clicked ec-audio-gallery-item
     */
    switchAudioTrack(newTrackIndex) {
      let previousTrackIndex = this.selectedTrack;

      this.emit('ec-play-audio', {action: 'switch', track: { ...this.tracks[newTrackIndex], ...{index: newTrackIndex} }});
      
      const allGalleryElements = this.querySelectorAll('ec-audio-gallery-item');

      this.toggleSelectedClass(allGalleryElements[previousTrackIndex], false);
      this.toggleSelectedClass(allGalleryElements[newTrackIndex], 'selected');

      this.selectedTrack = newTrackIndex;
    }

    toggleSelectedClass(galleryElement, selected = false) {
      galleryElement.classList.toggle('ec-audio-gallery-item--selected', selected);
    }

    /**
     * Recursive loop to find out if the user clicked on an 'ec-audio-gallery-item'
     * @param target HTML Element
     * @param audioGalleryId id of ec-audio-gallery
     * @returns the element or false
     */
    clickedOnGalleryItem(target, audioGalleryId) {
      if (target.is == 'ec-audio-gallery-item') {
        return target
      } else if (target.id == audioGalleryId) { // Clicked the container, break
        return false
      }

      return this.clickedOnGalleryItem(target.parentElement, audioGalleryId);
    }

    /**
     * Creating a new set of gallery items
     * @param galleryContainer 
     */
    createAudioItems(tracks, galleryContainer) {
      this.removeChildrenFrom(galleryContainer);

      const audioGalleryNode = this.querySelector("#audio-gallery-item").content.firstElementChild;
      const container = document.createElement('div');
      let galleryItem, track, title;

      for (let i = 0; i < tracks.length; i++) {
        track = tracks[i];

        title = track['title'][this.lang] || track['title']['sv'];

        galleryItem = audioGalleryNode.cloneNode();
        galleryItem.setAttribute('title', title);
        galleryItem.setAttribute('data-index', i);
        this.toggleSelectedClass(galleryItem, i == this.selectedTrack);
        container.appendChild(galleryItem);  
      }

      galleryContainer.appendChild(container);
    }

    removeChildrenFrom(galleryContainer) {
      while (galleryContainer.firstChild) {
        galleryContainer.firstChild.remove();
      }
    }

    /**
     * Downloading metadata in order to set the duration in memory and in ec-audio-gallery-item
     * @param galleryId The current active gallery - these are stored for the entire life cycle of the app
     * @param tracks All audio tracks
     * @param audioTrackItems All child elements in the gallery - ec-audio-gallery-item
     */
    updateMetadata(galleryId, tracks, audioTrackItems) {
      for (let i = 0; i < tracks.length; i++) {
        let durationTime = this.getStoredDurationFor(galleryId, tracks[i].name);

        if (durationTime) {
          audioTrackItems[i].setAttribute('duration', getTimeString(durationTime, 'fromSeconds'));
        } else {
          const lang = getCurrentLanguage();
          let audio = new Audio();

          audio.onloadedmetadata = () => {
            this.storeDurationFor(galleryId, tracks[i].name, audio.duration);
            audioTrackItems[i].setAttribute('duration', getTimeString(audio.duration, 'fromSeconds'));
            this.onloadedmetadata = null;
          }
  
          audio.preload = 'metadata';
          audio.src = `${process.env.SCALEWAY_ASSETS_URL}audio/${this.VERSION_CODE}/${lang}/${tracks[i].name}.mp3`;  
        }
      }
    }

    /**
     * Listens to ec-timestamp-visualizer event sent out by ec-audio-player
     * @param {Object} detail event detail
     */
    handleDurationChange({detail}) {
      let duration = detail.duration;
      let selectedTrack = detail.selectedTrack;

      if (typeof duration !== 'undefined' && typeof selectedTrack !== 'undefined') {
        let trackName = this.tracks[detail.selectedTrack].name;
        let audioTrackItems = this.galleryContainer.firstChild.children;
        
        this.storeDurationFor(this.galleryId, trackName, duration);
        audioTrackItems[selectedTrack].setAttribute('duration', getTimeString(duration, 'fromSeconds'));
      }
    }

    /**
     * If we already loaded the gallery once, it's uncessesery to load meta-data again
     * @param galleryId id of the audio gallery
     * @param trackName id of the track file
     * @returns {null, String} depending on if it exists in the used language
     */
    getStoredDurationFor(galleryId, trackName) {
      return this.trackDurations[this.lang]?.[galleryId]?.[trackName];
    }

    storeDurationFor(galleryId, trackName, duration) {
      this.trackDurations[this.lang] = this.trackDurations[this.lang] || {};
      this.trackDurations[this.lang][galleryId] = this.trackDurations[this.lang][galleryId] || {};

      this.trackDurations[this.lang][galleryId][trackName] = duration;
    }

}

customElements.define('ec-audio-gallery', EcAudioGallery);
