/** 
* 8th Wall crops the image target, so this scales the image up to it's real size
* 'minDimension' is the smallest of either height or width, basically saying if it's a landscape or portrait image
* 'maxDimension' is used sometimes when the 8thwall  is forcing cropping the image 
* @param {Element} el The 3D model that the image is projected upon
* @param {float} width in meter
* @param {float} height in meter
* @param {float} dimension either 'minDimension' or 'maxDimension', based on scale_on_max_dimension set in the manifest file.
*/
const setScale = (el, width, height, dimension) => {
  _setScale(el, _calculateDimensions(width, height, dimension));
}

const _calculateDimensions = (width, height, dimenion) => {
  return {
    scaledWidth:  dimenion * (width > height ? width / height : 1),
    scaledHeight: dimenion * (height > width ? height / width : 1)
  }
}

/**
 * Scales the flat surface (a 3D element) that the image is projected upon, just like transform: scale() in CSS
 * @param {Element} el The model
 * @param {Object} _calculateDimensions 1 is not scaling at all.
 */
const _setScale = (el, {scaledWidth, scaledHeight}) => {
  el.object3D.scale.set(scaledWidth, scaledHeight)
}

export { setScale }
