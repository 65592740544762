import * as Sentry from '@sentry/browser';

// Set up Sentry if enabled
Sentry.init({
  environment: process.env.ENV,
  enabled: process.env.ENABLE_SENTRY,
  release: process.env.SENTRY_RELEASE,
  dsn: process.env.SENTRY_DSN,
  ignoreErrors: ['updateEnterInterfaces'],
  integrations: integrations => {
    const feedbackIntegration = Sentry.feedbackIntegration({
      autoInject: false,
      showBranding: false,
      colorScheme: 'light',
    });

    const feedbackButton = document.querySelector(".ec-feedback-button");
    feedbackIntegration.attachTo(feedbackButton);

    const extras = [
      Sentry.replayIntegration(),
      feedbackIntegration,
    ];

    integrations.push(...extras);
    return integrations.filter(integration => {
      return integration.name !== "Breadcrumbs"; // Disable breadcrumbs for now.
    });
  },
  maxBreadcrumbs: 10,               // Reduce to 10 breadcrumbs to get around payload size
  tracesSampleRate: 1.0,            // Capture 100% transactions for performance monitoring
  replaysSessionSampleRate: 0.1,    // Capture Replay for 10% of all sessions
  replaysOnErrorSampleRate: 1.0,    // ...plus for 100% of sessions with an error
});

// Output author credits
import creditString from './src/js/utils/credits.js';
console.info(creditString);

// Import styles
import './src/scss/common.scss';
import './src/scss/spacing.scss';
import './src/scss/colors.scss';
import './src/scss/animation.scss';
import './src/scss/elements/loading-screen.scss';

// Import custom elements
import './src/js/elements/ec-notification-banner/ec-notification-banner.js';
import './src/js/elements/ec-onboarding/ec-onboarding.js';
import './src/js/elements/ec-close-button/ec-close-button.js';
import './src/js/elements/ec-timestamp-visualizer/ec-timestamp-visualizer.js';
import './src/js/elements/ec-audio-player/ec-audio-player.js';
import './src/js/elements/ec-dialog/ec-dialog.js';
import './src/js/elements/ec-game-console/ec-game-console.js';
import './src/js/elements/ec-gallery/ec-gallery.js';

// import './src/js/elements/ec-image-target-debug-alignment/ec-image-target-debug-alignment.js'

// Import materials and shaders
import 'aframe-chromakey-material';

// Import components and primitives
import { pipelineAdditions } from './src/js/components/pipeline-additions/pipeline-additions.js';
import { loadingLocalization } from './src/js/components/loadscreen/loading-localization.js';
import { updateTracker } from './src/js/components/update-tracker/update-tracker.js';
import { images360 } from './src/js/components/images-360/images-360.js';
import { cameraFeedDelegator } from './src/js/components/image-targeting/camera-feed-delegator.js';
import { ecNamedImageTarget } from './src/js/components/image-targeting/ec-named-image-target.js';
import { playVideoComponent } from './src/js/components/ec-play-video/ec-play-video.js';
import { imageFadeComponent } from './src/js/components/ec-image-fade/ec-image-fade.js';
import { modelViewer } from './src/js/components/model-viewer/model-viewer.js';

// Register components and primitives
AFRAME.registerComponent('pipeline-additions', pipelineAdditions);
AFRAME.registerComponent('loading-localization', loadingLocalization);
AFRAME.registerComponent('update-tracker', updateTracker);
AFRAME.registerComponent('images-360', images360);
AFRAME.registerComponent('camera-feed-delegator', cameraFeedDelegator);
AFRAME.registerComponent('ec-named-image-target', ecNamedImageTarget);
AFRAME.registerComponent('ec-play-video', playVideoComponent);
AFRAME.registerComponent('ec-image-fade', imageFadeComponent);
AFRAME.registerComponent('model-viewer', modelViewer);

// Debug variables
/* console.log('ENV', process.env.ENV);
console.log('DEBUG', process.env.DEBUG);
console.log('SENTRY', process.env.ENABLE_SENTRY); */
