import { removePointFromSearchParams } from "../../utils/search-params";

const images360 = {
  get is() {
    return '360-images';
  },

  id: null,

  /**
   * Init 360 image.
   *
   * @return void
   */
  init() {
    window.addEventListener('ec-show-360', this.trigger360Image.bind(this));
    window.addEventListener('360-images-close', this.close360Image.bind(this));
  },
  /**
   * Component cleanup
   *
   * @return void
   */
  remove() {
    window.removeEventListener('ec-show-360', this.trigger360Image, false);
    window.removeEventListener('360-images-close', this.close360Image, false);
  },
  /**
   * Adds a-sky element to the DOM
   *
   * @param detail - Event with data from manifest.
   * @return void
   */
  trigger360Image({detail}) {
    this.id = detail.id;
    const node = document.createElement('a-sky');

    node.setAttribute('src', `${process.env.SCALEWAY_ASSETS_URL}img/360/${detail.id}.jpg`);
    node.id = 'image-360';
    node.crossOrigin = 'anonymous';
    this.el.sceneEl.appendChild(node);
    this.showCloseButton();
  },
  /**
   * Removes a-sky element from DOM
   *
   * @return void
   */
  close360Image() {
    removePointFromSearchParams(this.id, 'always-remove');
    const node = document.getElementById('image-360');
    if (node) node.remove();
  },
  /**
   * Show the close button.
   *
   * @return void
   */
  showCloseButton() {
    this.el.emit('ec-close-button', {
      name: '360-images-close',
      action: 'show',
      data: {
        name: 'image-360',
      }
    });
  },
};

export { images360 };
