import { setScale } from "../image-targeting/image-target-helper";

const imageFadeComponent = {
  schema: {
    imageurl: {type: 'string'},
    scaleonmax: {type: 'boolean'},
  },

  animationDuration: 600,

  /**
   * Fades in an image by changing the opacity of the material component on the entitiy
   */
  init() {
    if (this.data.imageurl == '') { return }

    const image = document.createElement('img');
    image.crossOrigin = 'anonymous';
    image.src = this.data.imageurl;

    // If 8thwall cropped the wrong dimension, scale_on_max_dimension is set in the manifest file
    const dimensionToScale = this.data.scaleonmax ? this.el.components.maxDimension : this.el.components.minDimension;

    const {material} = this.el.components.material;
    material.opacity = 0;

console.log(this.is, this.data.scaleonmax, dimensionToScale == this.el.components.maxDimension, dimensionToScale == this.el.components.minDimension);

    const imageLoaded = () => {
      this.el.setAttribute('material', 'src', image);

      setScale(this.el, image.width, image.height, dimensionToScale);

      image.onload = null;
      opacityChange(Date.now(), 0);

      // for the game
      this.el.parentEl.components['ec-named-image-target'].data.hasPlayed = true;

      image.onload = null;
    }

    // Couldn't get a-frame's animation component to work so I implemented this instead :: Rickard
    const opacityChange = (startTime) => {
      if (Date.now() - startTime < this.animationDuration) {
        material.opacity = 1 * (Date.now() - startTime) / this.animationDuration;
        requestAnimationFrame(() => opacityChange(startTime));
      } else {
        material.opacity = 1;
      }
    }

    if (image.complete) { imageLoaded() }
    else { image.onload = imageLoaded }
  },

  remove() {
    this.animationDuration = 0;
    this.image = null;
  }
}

export {imageFadeComponent}
