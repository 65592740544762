/**
 * Handles image tracking, creates entities and delegates them to a pool of 'ec-named-image-target'
 */

import { pauseQRScanMomentarily } from "../../utils/camera-feed";
import { getCurrentLanguage } from "../../utils/language-helper";
import { removePointFromSearchParams } from "../../utils/search-params";
import { sendErrorReport } from "../../utils/sentry-error";

export default class DynamicTargeting {
  TWO_SECONDS = 2000;
  targets = [];       // all image targets in the room
  newTargets = [];    // a set of max 10 image targets that 8th Wall is scanning for
  found = new Set();  // all image targets that can be found. The max pool is set to 3 in index.html.
  lost = new Set();   // temporary sets a lost image target to be removed within TWO_SECONDS
  intervalId = -1;    // when rotating image targets, if more than 10

  constructor() {
    this.videoEl = document.getElementById("imagetarget-video")
    this.imageEl = document.getElementById("imagetarget-image")

    this.videoEl.removeAttribute('id');
    this.imageEl.removeAttribute('id');
  }

  /**
   * Always nice to type 'this.is' when using console.log
   */
  get is() { 
    return 'dynamic-targeting'
  }

  /**
   * Sets new target images that the app should scan for
   * @param {Array} imageTargets A list of image target ids
   */
  updateTargetElements(imageTargets) {
    clearInterval(this.intervalId);

    if (imageTargets?.length > 10) {
      this.targets = imageTargets;
      this._switchImageTargets();

      this.intervalId = setInterval(this._switchImageTargets.bind(this), 2000);
    } else if (imageTargets) {
      this._setCameraImageTracking(imageTargets);
    } else {
      console.warn(this.is, "No image tracking");
      this._setCameraImageTracking([]);
    }
  }

  /**
   * Tells a-frame to switch image targets
   * @param {Array} imageTargets Max 10, and 5 if SLAM is activated
   */
  _setCameraImageTracking(imageTargets) {
    XR8.XrController.configure({imageTargets: imageTargets});
  }

  /**
   * Used in an interval to constantly switch image targets
   */
  _switchImageTargets() {
    this.newTargets = this.targets.splice(0, 10 - this.found.size);
    this.targets.push(...this.newTargets);

    this._setCameraImageTracking([...Array.from(this.found), ...this.newTargets]);
  };
  
  
  /**
   * Adds image target unless recently lost or already has the target
   */
  foundTarget(imageTargetName, {scaledWidth, scaledHeight}, sceneEl, point) {
    // console.log(this.is, "found target", imageTargetName, point);

    pauseQRScanMomentarily(true);

    if (this.lost.has(imageTargetName))  {
      this.lost.delete(imageTargetName)
    } else if (!this.found.has(imageTargetName)) {
      this.found.add(imageTargetName);
      this.targets.splice(this.targets.indexOf(imageTargetName), 1);

      let childEl = this._setupImageTargetAction(imageTargetName, point);

      if (childEl) {  // if only playing an audio, then we don't need to show anything
        let imageTargetEl = sceneEl.components.pool__imagetarget.requestEntity();

        if (imageTargetEl) {
          // For scaling
          childEl.components.minDimension = Math.min(scaledWidth, scaledHeight);
          childEl.components.maxDimension = Math.max(scaledWidth, scaledHeight);

          imageTargetEl.appendChild(childEl);
          imageTargetEl.components.type = point.type; // for debugging
          imageTargetEl.setAttribute('ec-named-image-target', `name: ${imageTargetName}`);  
        } else {
          this.found.remove(imageTargetName);
          sceneEl.components.pool__imagetarget.returnEntity(imageTargetEl);
        }
      }
    }
  }

  /**
   * Removes image target unless the component is playing (isActive)
   */
  lostTarget(imageTargetName, sceneEl) {
    // console.log(this.is, "lost target", imageTargetName);

    for (const imageTargetComponent of sceneEl.components.pool__imagetarget.usedEls) {
      if (imageTargetComponent.getAttribute('ec-named-image-target')?.name == imageTargetName
        && !imageTargetComponent.components.isActive) {
        this.lost.add(imageTargetName);

        setTimeout(() => { // Avoids temporary loosing target
          if (this.lost.has(imageTargetName)) { // can be removed in foundTarget()
            this.removeTarget(imageTargetName);
          }
        }, this.TWO_SECONDS);
      }
    }
  }
  
  removeTarget(name) {
    // console.log(this.is, "remove target", name);

    this.lost.delete(name);
    this.found.delete(name);
    this.targets.push(name);

    // Removes point in search params so element can be triggered again
    removePointFromSearchParams(name);

    // Tell 'ec-named-image-target' component that the entity should be removed
    window.dispatchEvent(new CustomEvent(
      'ec-remove-image-target',
      {detail: {name: name}}
    ));
  }

  /**
   * Actions for specific points when triggered by image targets
   * 
   * @param {string} imageTargetName detected by 8th Wall
   * @param {object} point from manifest
   * @return {HTMLElement} to be added inside a-scene
   */
  _setupImageTargetAction(imageTargetName, point) {
    let childEl;
    let currentLang = (point.lang) ? '/' + getCurrentLanguage() : '';

    switch (point.type) {
      case '3d':
      case 'audio':
      case 'gallery':
      case 'game':
        return null
  
      case 'video':
        childEl = this.videoEl.cloneNode();

        childEl.setAttribute('ec-play-video', {
          'videourl': `${process.env.SCALEWAY_ASSETS_URL}video${currentLang}/${imageTargetName}.mp4#t=0.05`,
          'autoplay': point.auto_play,
          // 'thumb': point.auto_play
          //   ? '#imagetarget-target-found-thumbnail'   // this isn't used, but it's for future implementations
          //   : '#imagetarget-video-thumbnail',
          'loop': (typeof point.loop != 'undefined') ? point.loop : point.auto_play,
          'scaleonmax': point.scale_on_max_dimension ? true : false
        });

        return childEl;

      case 'image':
        childEl = this.imageEl.cloneNode();
        childEl.setAttribute('ec-image-fade', {
          'imageurl': `${process.env.SCALEWAY_ASSETS_URL}img/colorized${currentLang}/${imageTargetName}.jpg`,
          'scaleonmax': point.scale_on_max_dimension ? true : false
        });

        return childEl

      default:
        let errorMessage = `No image target of type "${point.type}" found`;
        console.log(errorMessage, point);
        sendErrorReport(errorMessage);
        break;
    }
  }
}
