import { removePointFromSearchParams } from "../../utils/search-params";

const modelViewer = {
  get is() {
    return 'model-viewer';
  },

  __toggleModelListener: null,
  loading: false,
  spinner: null,
  node: null,

  /**
   * Init 3D model.
   *
   * @return {void}
   */
  init() {
    this.__toggleModelListener = this.handleEvent.bind(this);
    window.addEventListener('ec-show-3d-model', this.__toggleModelListener);
  },
  /**
   * Component cleanup
   *
   * @return {void}
   */
  remove() {
    window.removeEventListener('ec-show-3d-model', this.__toggleModelListener);
    __toggleModelListener = null;
  },
  /**
   * Toggles the visiblity of the 3D object
   *
   * @param {object} detail - Event with data from the manifest.
   */
  handleEvent({detail}) {
    if (detail.action == 'start') {
      this.showSpinner();
      this.showBanner();
      this.play(); // activate tick method
      this.toggleCloseButton('show', detail.id);
      this.addModel(detail);
    } else {
      this.pause();
      this.close3dModel();
      this.toggleCloseButton('close');

      if (this.spinner) {
        this.removeSpinner();
        this.hideBanner();  
      }

      removePointFromSearchParams(detail.id, 'always-remove');
    }
  },
  /**
   * Hides the onboarding after the 3D model has loaded
   */
  tick() {
    if (this.spinner) {
      if (this.node.getObject3D('mesh')) {
        this.removeSpinner();
        this.hideBanner();  
      }
    } else {
      this.pause();
    }
  },
  showBanner() {
    this.el.emit('ec-notification-banner', {
      action: 'show',
      title: 'model_loading_title',
      body: 'model_loading_body',
    });
  },
  hideBanner() {
    this.el.emit('ec-notification-banner', {
      action: 'hide',
    });
  },
  /**
   * Recreating the spinner from 8th Wall
   *
   * @return {void}
   */
  showSpinner() {
    if (this.spinner === null) {
      this.spinner = document.createElement('img');
      this.spinner.id = 'loadImage';
      this.spinner.classList = 'spin';
      this.spinner.src = '//cdn.8thwall.com/web/img/loading/v2/load-grad.png';

      document.body.appendChild(this.spinner);
      }
  },
  removeSpinner() {
    if (this.spinner) {
      this.spinner.remove();
      this.spinner = null;  
    }
  },
  /**
   * Adds 3D object to the DOM
   *
   * @param point - Event with data from manifest.
   */
  addModel(point) {
    // Note - this probably means that it doesn't work to scan two models after each other
    if (this.node === null) {
      this.node = document.createElement('a-gltf-model');
      this.node.classList.add('cantap');
      this.node.crossOrigin = 'anonymous';
      this.node.id = 'model';
  
      this.node.setAttribute('src', `${process.env.SCALEWAY_ASSETS_URL}model/${point.id}.glb`);
      this.add3DAttributesTo(this.node, point.attributes);
  
      document.getElementById('camera').appendChild(this.node);

    }
  },
  /**
   * Adds attributes to 3D object
   *
   * @param {Element} node - model element
   * @param {object} point - Event with data from manifest.
   * @return {void} ... but changes the node itself
   */
  add3DAttributesTo(node, attributes) {
    if (attributes.components) {
      for (const component of attributes.components) {
        node.setAttribute(component.name || component, component.values || '');
      }
    }

    // NOTE: position is relative to the camera because the model is fixed to it
    for (const attribute of ["scale", "rotation", "position"]) {
      if (attributes[attribute]) {
        node.setAttribute(attribute, attributes[attribute]);
      }
    }
  },
  /**
   * Removes 3d model element from DOM
   *
   * @return {void}
   */
  close3dModel() {
    if (this.node) {
      this.node.remove();
      this.node = null;
    }
  },
  /**
   * Toggles the close button
   *
   * @param action
   * @return void
   */
  toggleCloseButton(action = 'show', modelId) {
    this.el.emit('ec-close-button', {
      name: 'ec-show-3d-model',
      action: action,
      data: {
        action: 'close',
        source: 'ec-close-button',
        id: modelId
      }
    });
  }
};

export { modelViewer };
