/**
 * 
 * @param {string} timeInMillis from manifest file
 * @returns {string} event name
 */
const convertToMinutesAndSeconds = (timestamp, fromSeconds) => {
  let convertFromMilliSeconds = fromSeconds ? 1 : 1000;

  let minutes = Math.floor(timestamp / 60 / convertFromMilliSeconds);
  timestamp = timestamp - minutes * 60 * convertFromMilliSeconds;
  let seconds = Math.floor(timestamp / convertFromMilliSeconds);

  return {
    minutes: minutes,
    seconds: seconds
  }
};

const getTimeString = (timestamp, fromSeconds) => {
  let {minutes, seconds} = convertToMinutesAndSeconds(timestamp, fromSeconds);

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

export {getTimeString};
