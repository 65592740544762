import EcCustomElement from "../ec-custom-element";
import "./components/ec-clue-icon";
import "./components/ec-game1-nar-blev-helsingborg-en-stad.js";
import '../../../scss/elements/ec-fab-button.scss';

import { localize } from "../../utils/language-helper";
import { restartCamera, setQRCodeScanningInactive } from "../../utils/camera-feed";
import { removePointFromSearchParams } from "../../utils/search-params";
import ProcessStorage from "./game-console-progress";
import { analyticsSendTrigger } from "../../utils/matomo-analytics";

/**
 * The game has a linear progress - 'steps' - that sends out events to activate other elements in the app
 */
export default class EcGameConsole extends EcCustomElement {
  __toggleGameListener = () => {};
  steps = [];                // the number of steps the user needs to go through
  clues = {};                // information about the next clue, displayed in a banner
  processStorage = () => {}; // saves the progress´ in localStorage
  gameName = '';             // game id
  idPrefix = '';             // game1, game2...
  nameOfPreviousStep = 'no-event';
  currentProgress = -1;      // which step that is currently under progresss
  currentClue = -1;          // how many clues that have been shown to the user
  stepToShowClueIcon = -1;   // the manifest controls when the clue token is visible

  html() {
    return `
      <ec-clue-icon class="ec-fab-button ec-fab-button--left ec-fab-button--small hide-animation hide-animation--hidden" style="z-index: 10"></ec-clue-icon>
      <ec-game1-nar-blev-helsingborg-en-stad><ec-game1-nar-blev-helsingborg-en-stad>
    `
  }

  get isActive() { return this.gameName !== ''; }

  constructor() {
    super();

    // Listener for specific steps
    this.__componentFinishedListener = this.componentEndCondition.bind(this);

    window.addEventListener('ec-start-game', this.handleEvent.bind(this));
    window.addEventListener(this.is, this.handleEvent.bind(this));
  }

  /**
   * Starts or stops the game
   *
   * @param {object} detail - Event with data from the manifest.
   * @return {void}
   */
  handleEvent({detail}) {
    if (detail.action == 'start') {
      this.startGame(detail);
      this.toggleCloseButton();
    } else {
      this.closeGame();
      this.toggleCloseButton('close');
    }
  }

  /**
   * Init variables and runs the first step
   * Displays a banner as a coachmark for users that resume the game
   *
   * @param {Object} data
   */
  startGame(data) {
    this.setupGame(data);
    setQRCodeScanningInactive(true);
    this.showPreviousClueBanner();
    this.runStep(this.steps[this.currentProgress]);
  }

  showPreviousClueBanner() {
    const FRESH_START = -1;
    let index = this.currentProgress - 1;

      // currentClue is set in updateNotificationbanner()
    while(index > FRESH_START && this.currentClue == FRESH_START) {
      this.updateNotificationBanner(this.steps[index].clue_index);
      index--;
    }

    this.notifyClueIcon(this.currentClue);
  }

  setupGame(data) {
    this.idPrefix = data.id_prefix;                         // used to distinguish games from each other
    this.gameName = this.idPrefix + data.name;              // used for analytics and storage
    this.steps = data.features.steps;                       // linear progress that the user goes through
    this.clues = data.features.clues;                       // banner information to the user during the progress
    this.stepToShowClueIcon = data.step_to_show_clue_icon;  // hides clue icon until this point
    this.processStorage = new ProcessStorage(this.gameName);
    this.currentProgress = this.processStorage.read();      // how far the user have gone
    this.hasShownBanner = false;                            // for when the user takes up the game again
    this.activateCustomGameComponents(this.gameName);

    this.startTimer(this.gameName + '_time');
  }

  /**
   * Each game consists of several steps that are run in consecutive order
   *
   *  sends events to activate elements
   *  update banner with clues, if there is need for one
   *  tells image recognition that only a specific image target is sllowed
   *  sets up listeners that runs the next step
   *
   * @param {Object} current data from manifest file
   */
  runStep(current) {
    if (this.isActive) {
      this.activateElements(current.events);
      this.updateNotificationBanner(current.clue_index);
      this.isolateCurrentImageTarget(this.idPrefix + current.name);
      this.setComponentFinishedListener(this.idPrefix + current.name);
    }
  }

  /**
   * Notifies a container that contains custom elements specific for that particular game
   *
   * @param {string} gameName name set in manifest file
   */
  activateCustomGameComponents(gameName) {
    this.emit(gameName + '_component');
  }

  /**
   * Sending events based on the structure in the manifest file
   *
   * @param {array} events strings or objects: {id: "name", data: {}}
   */
  activateElements(events) {
    if (events) {
      for (const event of events) {
        if (event.id || event) {
          this.emit(event.id || event, event.data);
        } else {
          console.warn(this.is, 'An unhandled event', events);
        }
      }
    }
  }

  /**
   * Displays clues (coachmarks) for the user in form of a banner.
   * Translation key example for body: 'game1_clue0'
   *
   * @param {integer} clueIndex a step can include a clue_index in the manifest file
   */
  updateNotificationBanner(clueIndex) {
    if (typeof clueIndex != 'undefined') {
      this.currentClue = clueIndex;

      this.emit('ec-notification-banner', {
        action: 'show',
        title: `${localize('clue')}: ${clueIndex}/${this.clues?.images?.length}`,
        body: this.idPrefix + 'clue' + clueIndex,
      });
    }
  }

  /**
   * Restricts which images that can be shown when opening the clue gallery
   */
  notifyClueIcon(currentClue) {
    if (this.isActive && this.currentProgress >= this.stepToShowClueIcon) {
      for (let index = 0; index < this.clues.images.length; index++) {
        this.clues.images[index].visible = index <= currentClue;
      }

      this.emit('ec-clue-icon', {...this.clues, ...{action: 'gallery-update', id: this.gameName, isPartOfGame: true}});
    }
  }

  /**
   * Tells camera-feed-delegator if the game is active or not, and therefor can restrict reading image targets and QR codes
   *
   * @param {string} name - by being undefined, no image targets are isolated.
   */
  isolateCurrentImageTarget(name) {
    this.emit('ec-camera-feed-delegator', {name: name, action: 'isolate-origin'});
  }

  /**
   * Runs the next step, making sure to store the progress along the way
   */
  componentEndCondition() {
    if (this.isActive) {
      this.currentProgress++;
      this.processStorage.store(this.currentProgress);
      this.notifyClueIcon(this.currentClue);
      this.runStep(this.steps[this.currentProgress]);

      analyticsSendTrigger('game-process', this.gameName, this.currentProgress);
    }
  }

  /**
   * Sets up and removes listeners for the next step
   *
   * @param {string} name the name of the step
   */
  setComponentFinishedListener(name) {
    this.removeComponentFinishedListener(this.nameOfPreviousStep);

    if (name) {
      this.nameOfPreviousStep = name;
      window.addEventListener(name, this.__componentFinishedListener);
    }
  }

  removeComponentFinishedListener(name) {
    window.removeEventListener(name, this.__componentFinishedListener);
  }

  /**
   * Interval that stores the time the user played in localStorage about every 10 seconds
   * Made this solution to take 1) closing the game, or 2) inactivate the tab, into account
   *
   * @param {string} TIMER_STORAGE_KEY gameName + '_time
   */
  startTimer(TIMER_STORAGE_KEY) {
    let difference = 0;
    let time = Date.now()

    const SECOND = 1000;
    const TEN_SECONDS = SECOND * 10;

    let intervalId = setInterval(() => {
      if (this.isActive) {
        if (Date.now() - time < TEN_SECONDS) {
          difference += Date.now() - time;

          if (difference > TEN_SECONDS) {
            let storedTime = Number(localStorage.getItem(TIMER_STORAGE_KEY)) || 0;
            localStorage.setItem(TIMER_STORAGE_KEY, storedTime + difference);
            difference = 0;
          }
        }

        time = Date.now();
      } else {
        clearInterval(intervalId);
      }
    }, SECOND);
  }

  /**
   * User closes the game
   */
  closeGame() {
    if (this.isActive) {
      restartCamera(document.querySelector('a-scene'));

      this.activateCustomGameComponents(this.gameName);
      this.isolateCurrentImageTarget();
      setQRCodeScanningInactive(false);
      this.removeComponentFinishedListener(this.nameOfPreviousStep);
      this.emit('ec-notification-banner', {action: 'hide'});
      this.emit('ec-clue-icon', {action: 'hide'});
      this.toggleCloseButton('close');
      removePointFromSearchParams(this.gameName.slice(this.idPrefix.length), 'always-remove');
      this.currentClue = -1;

      if (this.currentProgress == this.steps.length - 1) {
        localStorage.removeItem(this.gameName + '_time');
        this.processStorage.remove();
      }
      

      this.gameName = '';
    }
  }

  /**
   * Show the close button.
   */
  toggleCloseButton(action = 'show') {
    this.emit('ec-close-button', {
      name: 'ec-start-game',
      action: action,
      layer: 1,
      data: {
        action: 'close',
      }
    });
  }
};

customElements.define('ec-game-console', EcGameConsole);
