import EcCustomElement from '../ec-custom-element';
import './../../../scss/elements/ec-audio-player.scss';
import './../../../scss/elements/ec-fab-button.scss';
import './components/ec-audio-gallery';

import { getCurrentLanguage, setCurrentLanguage } from '../../utils/language-helper';
import { removePointFromSearchParams } from '../../utils/search-params';
import { analyticsSendTrigger } from '../../utils/matomo-analytics';
import { sendErrorReport } from '../../utils/sentry-error';

export default class EcAudioPlayer extends EcCustomElement {
  id = '';
  isPartOfGame = false;
  selectedTrack = 0;
  errorMessages = 0;

  html() {
    this.VERSION_CODE = 'V2';

    return `
      <ec-audio-gallery class="ec-audio-gallery" version-code=${this.VERSION_CODE}></ec-audio-gallery>

      <div class="control-container">
        <div class="audio-controller__lang ec-fab-button ec-fab-button--left">
          <button id="audio-lang" class="ec-fab-button__button">
            <svg viewBox="0 0 31 28" xmlns:xlink="http://www.w3.org/1999/xlink">
              <use id="current-flag" xlink:href="bundle.svg#icon-flag-sv"/>
            </svg>
          </button>
        </div>

        <div class="audio-controller__selection ec-fab-button ec-fab-button--left hide-animation hide-animation--hidden">
          <button class="ec-fab-button__button">
            <svg viewBox="0 0 31 28" xmlns:xlink="http://www.w3.org/1999/xlink">
              <use id="selected-flag" xlink:href="bundle.svg#icon-flag-en"/>
            </svg>
          </button>
        </div>

        <div class="audio-controller__actions ec-fab-button ec-fab-button--large ec-fab-button--middle">
          <button class="audio-controller__play-pause ec-fab-button__button">
            <svg viewBox="0 0 24 24" class="audio-controller__pause hide-animation" xmlns:xlink="http://www.w3.org/1999/xlink">
              <use xlink:href="bundle.svg#icon-player-pause"/>
            </svg>
            <svg viewBox="0 0 24 24" class="audio-controller__play hide-animation hide-animation--hidden" xmlns:xlink="http://www.w3.org/1999/xlink">
              <use xlink:href="bundle.svg#icon-player-play"/>
            </svg>
          </button>
        </div>
      </div>

    `
  }

  /**
   * Init audio controller.
   *
   * @return void
   *
   */
  connectedCallback() {
    this.currentSource = '';

    this.player = document.querySelector('#audio-player');

    this.playPauseButton = this.querySelector('.audio-controller__play-pause');
    this.languageButton = this.querySelector('#audio-lang');
    this.selectableLanguageContainer = this.querySelector('.audio-controller__selection')
    this.selectableLanguageButton = this.selectableLanguageContainer.querySelector('button');

    this.isPlaying = false;

    this.currentLanguage = getCurrentLanguage();

    this.playPauseButton.addEventListener('click', this.playPause.bind(this));
    this.languageButton.addEventListener('click', this.toggleLangMenu.bind(this));
    this.selectableLanguageButton.addEventListener('click', this.changeLanguage.bind(this));
    this.player.addEventListener('error', this.handleError.bind(this));

    window.addEventListener('ec-play-audio', this.playerInputs.bind(this));

    this.player.addEventListener('loadeddata', this.playPause.bind(this));

    this.player.addEventListener('timeupdate', this.updateCurrentTime.bind(this));
    this.player.addEventListener('ended', this.rewindAudioFile.bind(this));
  }

  /**
   * Component cleanup.
   *
   * @return void
   */
  disconnectedCallback() {
    this.playPauseButton.removeEventListener('click', this.playPause, false);
    this.languageButton.removeEventListener('click', this.toggleLangMenu, false);

    window.removeEventListener('ec-play-audio', this.playerInputs, false);
  }

  /**
   * Handles player events.
   *
   * @param {object} detail - Player event
   * @return void
   */
  playerInputs({detail}) {

    switch (detail.action) {
      case 'start':
        if (this.id === detail.name) return;
        this.id = detail.id;
        this.isPartOfGame = detail.is_part_of_game;
        this.selectedTrack = 0;

        this.switchLangIcons(this.currentLanguage);
        this.toggleCloseButton();
        this.hideControllers(!!detail?.image_target && detail?.hide_controller !== 0);
        this.emit('ec-timestamp-visualizer', {visibility: true});
        this.swapSource(detail.tracks[0].name, this.currentLanguage);
        this.togglePlaybackIcons(true);
        break;
      case 'switch': // Called from the child element 'ec-audio-gallery'
        if (this.selectedTrack == detail.track.index) {
          this.playPause();
        } else {
          this.swapSource(detail.track.name, this.currentLanguage);
          this.togglePlaybackIcons(true);
        }

        this.selectedTrack = detail.track.index;
        break
      case 'close':
        this.stopPlayer(this.currentSource);
        this.toggleCloseButton('close');
        break;
      default:
        console.log(this.is, 'Unknown action: ', detail.action);
        break;
    }
  }

  /**
   * Handles player play/pause.
   * Note: it's called after it has loaded
   *
   * @return void
   */
  playPause() {
    if (!this.isPlaying) {
      this.player.play()
        .catch(error => {
          if (error.name === 'NotAllowedError') {
            this.isPlaying = false;
            this.showNotificationForIos();
            this.togglePlaybackIcons();
          } else {
            sendErrorReport(new Error(`Failed to load audio ${this.currentSource} for lang ${this.currentLanguage} in gallery ${this.id}`));
          }
        });

      this.updateDuration();
    } else {
      this.player.pause();
    }

    this.isPlaying = !this.isPlaying;
    this.togglePlaybackIcons();
  }

  /**
   * A fallback for iOS. When an audio is loaded on the site for the first time
   * If the user taps once on the player, it will autoplay the rest of the clips
   */
  showNotificationForIos() {
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
        action: 'show',
        title: 'ios_audio_loaded_title',
        body: 'ios_audio_loaded_body',
        animation: false,
        clickAction: () => {
          if (!this.isPlaying) {
            this.playPause();
          }
        },
      }
    }));
  }

  hideNotification() {
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
      action: 'hide',
    }}));
  }

  /**
   * Hides and stops player.
   *
   * @return void
   */
  stopPlayer(pointId) {
    this.id = '';
    this.isPlaying = false;
    this.sendProgressToAnalytics();
    this.classList.add('hide-animation--hidden');
    this.player.pause();
    this.player.currentTime = 0;
    this.updateCurrentTime();
    this.hideNotification();
    removePointFromSearchParams(pointId, !this.isPartOfGame);
    this.selectableLanguageContainer.classList.add('hide-animation--hidden');

    this.emit(pointId); // for the game
  }

  /**
   * Sends every 10th percentage to Mamoto
   *
   * @return void
   */
  sendProgressToAnalytics() {
    if (this.player) {
      const wholeTenPercentage = Math.round(this.player.currentTime / this.player.duration * 10)*10;
      analyticsSendTrigger('audioProgress', "audio: " + this.currentSource, wholeTenPercentage);
    }
  }

  /**
   * 
   */
  hideControllers(hideControls) {
    if (!hideControls) this.classList.remove('hide-animation--hidden');
  }

  /**
  * Swap the image source
  *
  * @param {string} source - The new source filename
  * @return void
  */
  swapSource(source, lang) {
    this.errorMessages = 0;
    this.player.currentTime = 0;
    this.isPlaying = false;
    this.currentSource = source;

    this.player.setAttribute('src', `${process.env.SCALEWAY_ASSETS_URL}audio/${this.VERSION_CODE}/${lang}/${this.currentSource}.mp3`);
    this.player.load();
  }

  /**
   * Toggles play pause svg.
   *
   * @return void
   */
  togglePlaybackIcons(toggleState) {
    let showPause = (toggleState !== undefined) ? toggleState : this.isPlaying;
    this.playPauseButton.querySelector('.audio-controller__pause').classList.toggle('hide-animation--hidden', !showPause);
    this.playPauseButton.querySelector('.audio-controller__play').classList.toggle('hide-animation--hidden', showPause);
  }

  reverseLanguage(lang) {
    return (lang == 'en') ? 'sv' : 'en';
  }

  /**
   * Change language. Swapping the two langauages are hard coded through 'reverseLanguage'
   *
   * @return void
   */
  changeLanguage(detail) {
    const newLanguage = this.reverseLanguage(this.currentLanguage);

    this.swapSource(this.currentSource, newLanguage);

    if (detail !== 'changeLanguageForThisClipOnly') {
      this.currentLanguage = newLanguage;
      setCurrentLanguage(newLanguage);
      this.toggleLangMenu();
    }

    this.switchLangIcons(newLanguage);

    // Restart the audio if it's playing with new language
    if (this.isPlaying) {
      this.player.pause();
      this.player.play();
    }
  }

  /**
   * Handles errors from audio player.
   *
   * @param event - The event
   * @return void
   */
  handleError(event) {
    const error = event.target.error;
    this.errorMessages++

     // Prevents from constantly looping if reoccuring errors happens due to the Swedish file is missing
    if (this.errorMessages > 1) {
      return  
    }

    switch (error.code) {
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        if (this.currentLanguage !== 'sv') {
          this.changeLanguage('changeLanguageForThisClipOnly');
          this.displayErrorMessageToUser();
        }
        break;
      default:
        sendErrorReport(new Error('Media player error with code: ' + error.code));
        break;
    }
  }

  displayErrorMessageToUser(title = "I'm sorry!", body = "The audio isn't available in English.") {
    window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
        action: 'show',
        title: title,
        body: body,
      }
    }));

    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('ec-notification-banner', {detail: {
          action: 'hide',
        }
      }));
    }, 5000);
  }

  /**
  * Switches icons between the languages
  *
  * @param {string} code - The language code
  * return void
  */
  switchLangIcons(code) {
    this.languageButton.querySelector('#current-flag').setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `bundle.svg#icon-flag-${code}`);
    this.selectableLanguageButton.querySelector('#selected-flag').setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `bundle.svg#icon-flag-${this.reverseLanguage(code)}`);
  }
  /**
   * Called when the video ended
   */
  rewindAudioFile(event) {
    this.isPlaying = false;
    this.player.pause();

    document.querySelector('audio').currentTime = 0;
    this.updateCurrentTime();
    this.togglePlaybackIcons();
  }

  /**
   * Notifies 'ec-timestamp-visualizer' and 'ec-audio-gallery' about the duration
   * 'selectedTrack' is for 'ec-audio-gallery' only
   */
  updateDuration(event) {
    this.emit('ec-timestamp-visualizer', {duration: this.player.duration, selectedTrack: this.selectedTrack});
  }
    
  /**
   * Notifies 'ec-timestamp-visualizer' about the duration
   */
  updateCurrentTime(event) {
    this.emit('ec-timestamp-visualizer', {currentTime: this.player.currentTime});
  }

  /**
   * Toggle the language menu
   *
   * @return void
   */
  toggleLangMenu() {
    this.selectableLanguageContainer.classList.toggle('hide-animation--hidden');
  }

  /**
   * Toggles the close button
   *
   * @param action
   * @return void
   */
  toggleCloseButton(action = 'show') {
    if (action == 'close' || this.classList.contains('hide-animation--hidden')) {
      this.emit('ec-close-button', {
        name: 'ec-play-audio',
        action: action,
        data: {
          action: 'close',
          id: 'ec-close-button'
        }
      });
    }
  }
}

customElements.define('ec-audio-player', EcAudioPlayer);
