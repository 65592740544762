import { sendErrorReport } from '../../utils/sentry-error';

/**
 * Downloads and stores manifest files in memory
 * this.manifest contains all possible rooms with their ids
 * {
 *  1: {
 *    id: (Int)
 *    name: (String)
 *    points: {} Room information
 *    imageTargetIds (Array) Added in this file
 *    hasMerged: (Int) 0, 1  Added in this file
 *  }
 * }
 */

export default class ManifestDownloader {
  manifest = {};        // filled with downloaded manifest files
  failed = new Set();   // if any manifest failed to load
  RETRY_TIME = 2000;

  // If it's not in the list, then the manifest will download when the room code is triggered
  roomsToPreload = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  exists(roomId) { return this.getManifest(roomId) !== undefined}

  getManifest(roomId) { return this.manifest[roomId]; }

  updateManifest(roomId, data) { this.manifest[roomId] = data; }
  
  /**
   * Either download a room or merge it with other rooms.
   * @param {*} roomId 
   * @param {*} callbackFn 
   * @returns {Object}
   */
  async get(roomId = 1, callbackFn) {
    // Download the manifest first
    if (!this.exists(roomId)) {
      return this.download(roomId)
      .then(() => {
        return this.get(roomId, callbackFn);
      });
    }

    let sharedRooms = this.getManifest(roomId).shared_rooms; // array of room ids
    let sharedNames = this.getManifest(roomId).shared_names; // 1 if the rooms should merge the names

    // Downloads any of the shared rooms that are missing
    await this.downloadRooms(sharedRooms);

    const room = this.mergeWithRoom(roomId, sharedRooms, sharedNames);
    this.updateManifest(roomId, room);

    callbackFn({...room});  // Removing reference, so elements can modify it.
  }


  // MERGING ROOMS TOGETHER

  mergeWithRoom(roomId, sharedWith, hasSharedNames) {
    let currentRoom = this.getManifest(roomId);
    let sortedRoomIdArr = [Number(roomId), ...sharedWith].sort((a, b) => {
      return a > b ? 1 : -1;
    });

    if (currentRoom.hasMerged) {
      return currentRoom
    }

    currentRoom.hasMerged = 1;

    currentRoom = this.mergePointsAndImageTargets(currentRoom, sharedWith);
    currentRoom.name = this.mergeNames(currentRoom.name, sortedRoomIdArr, hasSharedNames);

    return currentRoom
  }

  mergePointsAndImageTargets(currentRoom, roomsToMerge) {
    for (const roomId of roomsToMerge) {
      let toBeMerged = this.getManifest(roomId);

       // Already merged the points and imageTargets in this manifest file
      if (toBeMerged?.hasMerged) {
        currentRoom.points = toBeMerged.points
        currentRoom.imageTargets = toBeMerged.imageTargets

        return currentRoom
      }

      if (toBeMerged) {
        currentRoom.points = {...currentRoom.points, ...toBeMerged.points};
        currentRoom.imageTargets = [...currentRoom.imageTargets, ...toBeMerged.imageTargets];
      }
    }

    return currentRoom
  }

  /**
   * Merge room names based on id order
   * @param {*} sortedRoomIdArr All room ids in order
   * @param {*} hasSharedNames if names should be merged
   * @returns {String} room name
   */
  mergeNames(currentRoomName, sortedRoomIdArr, hasSharedNames) {
    let _roomName;
    let combineChar = '&';

    if (hasSharedNames) {
      let sharedRoomNames = '';

      while(sortedRoomIdArr.length) {
        if (sharedRoomNames) {
          sharedRoomNames += ` ${combineChar} `;
        }

        _roomName = this.getManifest(sortedRoomIdArr.shift()).name;

       // Already merged the names in this manifest file
       if (_roomName.includes(combineChar)) { 
          return _roomName
        }

        sharedRoomNames += _roomName;
      }

      return sharedRoomNames
    }

    return currentRoomName
  }


  // DOWNLOADING MANIFEST FILES

  async download(roomId) {
    try {
      let room = await fetch(`/assets/manifests/${roomId}.json`);
      room = await room.json();

      room.imageTargets = this.addImageTargetIds(room.points);
      room.points = this.removeAudioFiles('not_delivered', room.points);

      this.updateManifest(roomId, room);

      this.failed.delete(roomId);

      return room;
    } catch (error) {
      this.failed.add(roomId);

      sendErrorReport(new Error(`Couldn't download manifest for room ${roomId}`));
      return {}
    }
  }



  /**
   * Fetches all manifests, and retries if anyone fails
   */
  async getAll() {
    await this.downloadAll(this.roomsToPreload);

    if (this.failed.size) {
      setTimeout(() => {
        this.downloadAll(Array.from(this.failed));
      }, this.RETRY_TIME);
    }
  }

  async downloadAll(roomIdArr) {
    return this.downloadRooms(roomIdArr)
  }

  async downloadRooms(roomIdArr) {
    let roomsPromise = [];

    for (const roomId of roomIdArr) {
      if (!this.exists(roomId)) {
        roomsPromise.push(this.download(roomId));
      }
    }

    return Promise.all(roomsPromise);
  }

  /**
   * Creates an array of image targets that the app should scan for
   * 
   * @param {object} points 
   * @returns {[string]Array} list of image targets
   */
  addImageTargetIds(points) {
    let imageTargetIds = [], point = {};

    for (const pointId in points) {
      point = points[pointId];

      point.id = pointId;
      point.name = pointId;

      if (point.image_target) {
        imageTargetIds.push(pointId);
      }
    }

    return imageTargetIds;
  }

  /**
   * Removes all audio files that has a certain property, i.e. 'not_delivered'
   * 
   * @param {string} property which property that determines that the audio file is removed 
   * @param {Object} points all points in a room
   * @returns {Object} points
   */
    removeAudioFiles(property, points) {
      let point = {}, tracks = {};

      for (const pointId in points) {
        point = points[pointId];
        tracks = point.tracks;

        if (tracks) {
          point.tracks = tracks.filter(audio => {
            return audio[property] !== 1
          });
        }
      }
  
      return points;
    }
}
