import EcCustomElement from '../ec-custom-element';
import { getTimeString } from '../../utils/time-handling';
import './../../../scss/elements/ec-timestamp-visualizer-.scss';

export default class ECTimestampVisualizer extends EcCustomElement {
  duration = 0
  numberOfActivations = 0

  html() {
    return `
      <span id="ec-timestamp-visualizer-current-time">00:00</span>
      <div class="ec-timestamp-visualizer__bar"></div>
      <span id="ec-timestamp-visualizer-duration">00:00</span>
    `
  }

  connectedCallback() {
    window.addEventListener('ec-timestamp-visualizer', this.handleUpdate.bind(this));
    window.addEventListener('ec-show-360', this.hideVisualizer.bind(this));
    window.addEventListener('ec-show-3d-model', this.hideVisualizer.bind(this));
    window.addEventListener('ec-show-gallery', this.hideVisualizer.bind(this));
    window.addEventListener('ec-start-game', this.hideVisualizer.bind(this));
    window.addEventListener('ec-close-button', ({detail}) => {
      if (detail.action == 'close') {
        this.hideVisualizer();
      }
  });

    this.currentTimeElement = document.getElementById('ec-timestamp-visualizer-current-time');
    this.durationElement = document.getElementById('ec-timestamp-visualizer-duration');
  }

  disconnectedCallback() {
    window.removeEventListener('timestamp-visualizer', this.handleUpdate, false);
  }

  handleUpdate({detail}) {
    if (detail.duration) {
      this.setDuration(detail.duration);
    } else if (typeof detail.currentTime == 'number') {
      this.setCurrentTime(detail.currentTime);
    } else if (typeof detail.visibility != 'undefined') {
      this.toggleVisibility(detail.visibility);


      if (!detail.visibility) {
        this.resetValues();
      }
    }
  }

  updateBarProgress(timeInSeconds) {
    let progress = this.duration ? Math.floor(timeInSeconds / this.duration * 100) : 0;

    this.style.setProperty('--bar-progress-translation',  progress + '%');
  }

  setCurrentTime(timestamp) {
    this.currentTimeElement.innerText = getTimeString(timestamp, 'fromSeconds');
    this.updateBarProgress(timestamp);
  }

  setDuration(timestamp) {
    this.duration = timestamp;
    this.durationElement.innerText = getTimeString(timestamp, 'fromSeconds');
  }

  resetValues() {
    this.setCurrentTime(0);
    this.setDuration(0);
  }

  hideVisualizer(event) {
    this.numberOfActivations = -1;

    this.toggleVisibility(false);
    this.resetValues();
  }

  toggleVisibility(shouldShow) {
    // Fixing issue with race condition, where one element closes and another opens
    // TODO: probably needs an overhaul in how "remove" events are sent.
    this.numberOfActivations += (shouldShow) ? 1 : -1;
    this.numberOfActivations = Math.max(0, this.numberOfActivations);
    
    this.classList.toggle('hide-animation--hidden', !(shouldShow || this.numberOfActivations > 0));

    this.emit('ec-clue-icon', {action: 'translate', state: shouldShow});
  }
}

customElements.define('ec-timestamp-visualizer', ECTimestampVisualizer);
